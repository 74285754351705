import { sassTrue } from "sass";
import { TorusBufferGeometry } from "three";

export var mode = {
  modetest: false, // true:TEST BUTTONS // false:NO TEST BUTTONS
  environment: "dev", // "dev":development // "prod":production  // Console.log disabling
  modetestServerConnection: true, // true:test AWS Routes // false:production AWS Routes
  modetestCat: true, // true: load cat.png, false: load cat_beta.png
  notVideos: false, // true: not video // false: normal video
  NumEmotions: 18,
  NumHashtags: 18,
  RadiusEmoSphere: 8,
  ZoomCanvas: 2.7,
  GameAppHeight: "54vh",
  GameAppWidth: "80vw",
  selectHashtagsAutomatic: false, // true: automatic: Select random at ALL json 
                                 // types, circle mode
                                 // false: manual
  TypeInManualHashtags: "", // example: "politics" 
                            // REVIEW THE JSON, if not set or not exist, selecting current in JSON.
//phraseAtStartHome: "Something on your mind?", // PHRASE AT START HOMEPAGE
  phraseAtStartHome: "Choose the #Hashtag now!",
//hashtagPlaceHolder: "Type your sentence, hashtags, link, or what you want."
hashtagPlaceHolder: "...or write your #hashtag and Play."
};
